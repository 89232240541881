import { Component, OnInit } from '@angular/core';
import { Translation } from 'app/models/api/translation';
import { TranslationGroup } from 'app/models/api/translation-group';
import { RouteType, TranslationsSubRouteType } from 'app/routing/route-type';
import { LocaleService } from 'app/services/locale.service';
import { finalize, switchMap } from 'rxjs/operators';
import { BaseTranslationComponent } from 'app/components/translations/base-translation.component';
import { PageEvent } from '@angular/material/paginator';

const DEFAULT_PAGE_SIZE = 10;

export class TranslationItem {
    sourceValue?: string;
    targetValue?: string;
    modified = false;

    constructor(
        public readonly translationCodeId: number,
        public readonly translationCode: string,
        public readonly groupName: string,
    ) {}

    apply(translation: Translation, sourceLocaleId: number, targetLocaleId: number) {
        if (translation.localeId === sourceLocaleId) {
            this.sourceValue = translation.valueDevelopment;
        }
        if (translation.localeId === targetLocaleId) {
            this.targetValue = translation.valueDevelopment;
        }
    }
}

@Component({
    selector: 'translate',
    templateUrl: './translate.component.html',
    styleUrls: ['./translate.component.less'],
})
export class TranslateComponent extends BaseTranslationComponent implements OnInit {
    translations: TranslationItem[] = [];
    loading = false;
    searchQuery = '';
    sourceLocaleId = LocaleService.DEFAULT_LOCALE;
    targetLocaleId = LocaleService.DEFAULT_LOCALE;
    groupId = this.storageService.lastUsedTranslationGroupId;
    groups: TranslationGroup[] = [];
    pageNumber = 1;
    totalPages = 0;
    totalCount = 0;
    pageSize = this.storageService.paginatorPageSize ?? DEFAULT_PAGE_SIZE;
    untranslated = false;

    get translationsPublishRouterLink() {
        return ['/', RouteType.translations, TranslationsSubRouteType.publish];
    }
    get editAllowed() {
        return this.gemUser.isAdmin || this.gemUser.locales.some(item => item.localeId === this.targetLocaleId);
    }

    ngOnInit() {
        super.ngOnInit();
        this.route.queryParamMap.subscribe(params => {
            this.searchQuery = params.get('query') ?? '';

            const sourceLocaleParam = parseInt(params.get('sourceLocale') ?? '');
            this.sourceLocaleId = Number.isNaN(sourceLocaleParam) ? LocaleService.DEFAULT_LOCALE : sourceLocaleParam;

            const targetLocaleParam = parseInt(params.get('targetLocale') ?? '');
            this.targetLocaleId = Number.isNaN(targetLocaleParam)
                ? +(this.gemUser.locales.find(item => item.id !== `${LocaleService.DEFAULT_LOCALE}`)?.id ?? LocaleService.DEFAULT_LOCALE)
                : targetLocaleParam;

            if (params.get('groupId')) {
                this.groupId = parseInt(params.get('groupId') ?? '');
            }
            if (params.get('pageSize')) {
                this.pageSize = parseInt(params.get('pageSize') ?? '');
            }
            if (params.get('pageNumber')) {
                this.pageNumber = parseInt(params.get('pageNumber') ?? '');
            }
            if (params.get('untranslated')) {
                this.untranslated = params.get('untranslated') === `${true}`;
            }

            this.cd.markForCheck();
            this.search();
        });

        this.translationService.getGroups().subscribe(response => {
            this.groups = response.data;
            if (this.groupId === undefined) {
                this.groupId = 0;
            }
            if (this.groupId) {
                this.onInputChanged();
            }
        });
    }

    saveChanges(value: TranslationItem) {
        if (value.modified) {
            this.translationService
                .updateTranslations(this.targetLocaleId, [
                    {
                        translationCodeId: value.translationCodeId,
                        content: value.targetValue ?? '',
                    },
                ])
                .subscribe(() => {
                    if (!this.untranslated) {
                        this.search();
                    }
                });
        }
    }

    showDeleteTranslationDialog(translationCodeId: number) {
        this.showAlert({
            title: 'Are you sure you want to delete this translation?',
            confirmButtonTitle: 'Yes, delete',
        })
            .componentInstance.onConfirm.pipe(switchMap(() => this.translationService.deleteTranslation(translationCodeId)))
            .subscribe(() => this.search());
    }

    onInputChanged() {
        this.storageService.lastUsedTranslationGroupId = this.groupId;
        this.tryNavigate(
            this.buildUrl({
                query: this.searchQuery,
                sourceLocaleId: this.sourceLocaleId,
                targetLocaleId: this.targetLocaleId,
                groupId: this.groupId,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                untranslated: this.untranslated,
            }),
        );
    }

    onPageChanged(event: PageEvent) {
        this.storageService.paginatorPageSize = event.pageSize;
        this.tryNavigate(
            this.buildUrl({
                query: this.searchQuery,
                sourceLocaleId: this.sourceLocaleId,
                targetLocaleId: this.targetLocaleId,
                groupId: this.groupId,
                pageNumber: event.pageIndex + 1,
                pageSize: event.pageSize,
                untranslated: this.untranslated,
            }),
        );
    }

    onUntranslatedToggled(value: boolean) {
        this.untranslated = value;
        this.cd.markForCheck();
        this.onInputChanged();
    }

    private tryNavigate(url?: string | null) {
        if (url) {
            this.navigationService.navigateByUrl(url);
        }
    }

    private buildUrl({
        query,
        sourceLocaleId,
        targetLocaleId,
        groupId,
        pageSize,
        pageNumber = 1,
        untranslated,
    }: {
        query: string;
        sourceLocaleId: number;
        targetLocaleId: number;
        groupId?: number;
        pageNumber?: number;
        pageSize: number;
        untranslated?: boolean;
    }) {
        if (!targetLocaleId || !sourceLocaleId) {
            return undefined;
        }
        return (
            `/translations?sourceLocale=${sourceLocaleId}&targetLocale=${targetLocaleId}` +
            `&groupId=${groupId}${query.length > 0 ? '&query=' + query : ''}` +
            `&pageSize=${pageSize}&pageNumber=${pageNumber}&untranslated=${untranslated}`
        );
    }

    private search() {
        this.loading = true;

        const pageParams = { number: this.pageNumber, size: this.pageSize };
        const locales = [this.sourceLocaleId, this.targetLocaleId];
        this.translationService
            .getTranslations(locales, pageParams, {
                ...(this.groupId ? { groupId: this.groupId } : {}),
                ...(this.searchQuery ? { keyword: this.searchQuery } : {}),
                ...(this.untranslated ? { untranslated: true } : {}),
            })
            .pipe(
                finalize(() => {
                    this.loading = false;
                    this.cd.markForCheck();
                }),
            )
            .subscribe(response => {
                this.translations = [];
                response.data.forEach(element => {
                    let translationItem = this.translations.find(item => item.translationCodeId === element.translationCodeId);
                    if (!translationItem) {
                        translationItem = new TranslationItem(element.translationCodeId, element.translationCode, element.groupName);
                        this.translations.push(translationItem);
                    }
                    translationItem.apply(element, this.sourceLocaleId, this.targetLocaleId);
                });

                this.totalCount = response.meta?.totalCount ?? 0;
                this.totalPages = response.meta?.totalPages ?? 0;
                this.cd.markForCheck();
            });
    }
}
